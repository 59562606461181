import React from 'react'
import GeneralLayout from "../layouts/general";
import CleanLayout from "../layouts/clean";

class NotFoundPage extends React.Component {
    render() {
        return <CleanLayout pageTitle={'Not Found Page'}>
            <p>Not Found Page</p>
        </CleanLayout>
    }
}

export default NotFoundPage
